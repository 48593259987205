// 指令类型(1:转发 4 阅读 5 评论 6 点赞)
enum InstrcutionType {
  FORWARD = 1,
  READ = 4,
  COMMENT = 5,
  LIKE = 6,
}

export const parseInstructionType = (type: InstrcutionType) => {
  switch (type) {
    case InstrcutionType.FORWARD:
      return '转发';
    case InstrcutionType.READ:
      return '阅读';
    case InstrcutionType.COMMENT:
      return '评论';
    case InstrcutionType.LIKE:
      return '点赞';
    default:
      return '';
  }
};

// 接收状态 1 未接收 2 已接收 3 已完成
enum InstructionStatus {
  PENDING = '1',
  SUCCESS = '2',
  EXPIRED = '3',
}

/**
 * 处理状态为 1 待完成 2 已完成 3 已过期
 * @param status
 */
export const parseInstructionStatus = (status: InstructionStatus) => {
  switch (status) {
    case InstructionStatus.PENDING:
      return {
        status: '待完成',
        class: 'warning-status',
      };
    case InstructionStatus.SUCCESS:
      return {
        status: '已完成',
        class: 'success-status',
      };
    case InstructionStatus.EXPIRED:
      return {
        status: '已过期',
        class: 'error-status',
      };
    default:
      return {
        status: '',
        class: '',
      };
  }
};

// 指令渠道 1 公众号 3 抖音
enum InstructionChannel {
  WEIXIN = 1,
  DOUYIN = 3,
}

export const parseInstructionChannel = (status: InstructionChannel) => {
  switch (status) {
    case InstructionChannel.WEIXIN:
      return '微信';
    case InstructionChannel.DOUYIN:
      return '抖音';
    default:
      return '';
  }
};

export const removeEmptyStringVal = (obj: any) => {
  for (let key in obj) {
    if (obj[key] === '') {
      delete obj[key];
    }
  }
  return obj;
};

// 微信：获取url 中的 所有参数
// export const urlParams2Obj = () => {
//   const query = window.location.href.split("?")[1];
//   if (!query) { return }
//   const codeQueryArr = query.split("&");
//   let codeObj = {};
//   codeQueryArr.forEach((item: string) => {
//     let tempArr = item.split("=")
//     codeObj[tempArr[0]] = tempArr[1]
//   });
//   return codeObj
// }

//
/**
 * @description: 取url参数
 * @param {string} key 取参键名
 * @return {string|any} 返回值当传入key时返回具体值，当不传时返回整个url参数
 */
export const getUrlParams = (key?: string) => {
  // 取参数
  const search = window.location.search;
  const searchParams: any = {};
  search.substring(1).split('&').forEach(ele => {
    const [k, v] = ele.split('=');
    if (k) {
      searchParams[k] = v ? decodeURIComponent(v) : '';
    }
  });
  return key ? searchParams[key] : searchParams;
};

// 微信：获取url 中的 code 参数，用来获取 openid
export const getUrlCode = getUrlParams;

// 判断是否为空对象
export const isEmptyObj = (obj: any) => {
  if (JSON.stringify(obj) === '{}') {
    return true
  }
  return false
}
