<template>
  <div class="wrap">
    <router-view class="view"></router-view>
    <van-tabbar
      safe-area-inset-bottom
      :fixed="false"
      v-model="store.state.activeTabbar"
      @change="onChangeTabbar"
    >
      <template v-for="item in tabbarList" :key="item.name">
        <van-tabbar-item
          :name="item.name"
          :icon="item.icon"
          icon-prefix="rmy-icon"
          >{{ item.label }}</van-tabbar-item
        >
      </template>
    </van-tabbar>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const tabbarList = reactive([
      // { name: "resource", icon: "zixun", label: "资讯" },
      { name: "mine", icon: "wode", label: "我的" },
    ]);

    const store = useStore();
    // 处理菜单权限, 非宣传员权限无 task 列表
    const handleTabbarList = () => {
      let mission = { name: "task", icon: "renwu", label: "任务" };
      if (store.state.type.includes("WP")) {
        tabbarList.unshift(mission);
      }
    };

    // tabbar 改变处理
    const router = useRouter();
    const onChangeTabbar = (v: string): void => {
      store.commit("updateName", v);
      router.replace(v);
    };

    onMounted(() => {
      console.log("mounted");
      handleTabbarList();
    });

    return {
      store,
      tabbarList,
      onChangeTabbar,
    };
  },
});
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .view {
    flex: 1;
    height: 0;
  }
}
</style>
