import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

function getDefaultState() : any {
  return {
    activeTabbar: "",
    type: "", // WP
    userId: "",
    openId: '', // 微信openId
    unionId: '', // 微信unionId
    // 注意这里加了createPersistedState，token 会自动存储到 localStorage 中，
    // 若是去除了createPersistedState 则需要自行处理本地持久存储
    token: {
      access_token: '',
      token_type: 'bearer',
      expires_in: 0,
      expiresTime: new Date().toISOString(),
      refresh_token: '',
    },
    pageShow: true, // 页面是否展示
  }
}

export default createStore({
  state: getDefaultState(),
  getters: {
    token($state) {
      if (!$state.token.access_token) return '';
      if (new Date($state.token.expiresTime).getTime() <= Date.now()) {
        return '';
      }
      return $state.token.access_token;
    }
  },
  mutations: {
    resetStore(state) {
      // 如果是微信环境则保存openId和unionId，用于重新登录绑定(不需要再次发起微信授权)
      if (/MicroMessenger/.test(navigator.userAgent)) {
        const { openId, unionId } = state;
        Object.assign(state, getDefaultState());
        state.openId = openId || '';
        state.unionId = unionId || '';
        return;
      }
      Object.assign(state, getDefaultState());
    },
    updateName(state: { activeTabbar: any }, activeTabbar: string) {
      state.activeTabbar = activeTabbar;
    },
    updateUserType(state: { type: any }, type: string) {
      state.type = type;
    },
    updateUserId(state: { userId: any }, userId: string) {
      state.userId = userId;
    },
    setOpenId(state: any, val: string) {
      state.openId = val;
    },
    setUnionId(state: any, val: string) {
      state.unionId = val;
    },
    setToken(state: any, val: any) {
      if (val) {
        val.expiresTime = new Date(Date.now() + val.expires_in * 1000).toISOString();
        state.token = val;
        return
      }
      state.token = getDefaultState().token;
    },
    setPageShow(state, val: boolean) {
      state.pageShow = val;
    },
  },
  actions: {
    // 刷新页面组件
    async reloadPage({ commit }) {
      commit('setPageShow', false);
      return Promise.resolve().then(() => {
        commit('setPageShow', true);
      });
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
