import { createRouter, createWebHashHistory } from 'vue-router';
import { isEmptyObj } from '@/utils/tool';
import store from '@/store';
import { authLogin } from '@/utils/auth';
import routes from './routes';

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

const whiteList = ['Login', 'retrieve', 'reset', 'Clear'];

router.beforeEach(async (to, _from, next) => {
  // 清理地址，清理缓存返回登录页
  if (to.path === '/clear') {
    localStorage.clear();
    sessionStorage.clear();
    store.commit('resetStore');
    store.commit('setOpenId', '');
    store.commit('setUnionId', '');
    // 需要先执行下进入，否则会空白页
    next();
    window.location.replace(`${location.origin}/#/login`);
    return;
  }
  if (!store.getters.token && !whiteList.includes(to.name as string)) {
    await authLogin();
  }
  if (to.meta.title) {
    document.title = to.meta.title || '融媒云';
  }
  /**
   * 通过name 将路由 params 存到对应页面的 params 中，防止刷新丢失
   */
  const isEmptyParams = isEmptyObj(to.params);
  if (isEmptyParams) {
    // 取缓存路由参数并检查与当前路由是否一致，若一致则进行赋值，否则清理
    const routeParams = JSON.parse(sessionStorage.getItem('routeParams') || '{}');
    if (routeParams.name === to.name) {
      to.params = routeParams.params;
    } else {
      sessionStorage.removeItem('routeParams');
    }
  } else {
    sessionStorage.setItem('routeParams', JSON.stringify({ name: to.name, params: to.params }));
  }
  next();
});
router.afterEach((to) => {
  let titleTag = to.meta.title
  const win: any = window
  win._hmt = win._hmt || [];
  if (win._hmt) {
    win._hmt.push(['_setAutoPageview', false]);
    win._hmt.push(['_trackPageview', `/${titleTag}`]);
  }
});

export default router;
