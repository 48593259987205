<template>
  <div id="main">
    <router-view v-if="pageShow" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from 'vuex';
import flexible from "rem-flexible";

export default defineComponent({
  setup() {
    const store = useStore();
    onMounted(() => {
      flexible(750, 100);
      // url 带参数debug = 1 开启调试
      if (window.location.href.indexOf('debug=1') !== -1) {
        import('vconsole').then(({ default: VConsole }) => {
          (window as any).VConsole = new VConsole();
        });
      }
    });

    // 页面展示（一般用于刷新）
    const pageShow = computed(() => store.state.pageShow);

    return {
      pageShow,
    };
  }
});
</script>

<style lang="scss">
#main {
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  height: calc(100vh - var(--vh-offset, 0px));
  background: $BACKGROUND_COLOR;
}
</style>
