import axios from "axios";
import router from "@/router";
import { baseUrl } from "@/config";
import qs from 'qs';
import store from '@/store';

import { Toast } from "vant";

function toLogin(): void {
  store.commit('resetStore');
  sessionStorage.clear();
  router.push({ path: "/login" });
}

// 创建http
const http = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: [function (data, headers) {
    if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      // 把一个参数对象格式化为一个字符串
      return qs.stringify(data)
    } else if (headers['Content-Type'] === 'multipart/form-data') {
      return data
    } else {
      headers['Content-Type'] = 'application/json'
    }
    return JSON.stringify(data)
  }]
});

/* 请求拦截器 */
http.interceptors.request.use(
  function (config: any) {
    // 每次请求时会从store中获取token
    const token = store.getters.token;
    if (!config.headers.public && token) {
      config.headers["Authorization"] = `${store.state.token.token_type} ${token}`;
    }
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response: any) => {
    const res = response.data || null;
    if (response.status === 200 && res) {
      switch (res.resp_code) {
        case 0:
          break;
        case 401:
          toLogin();
          break;
        default:
          Toast({
            type: "fail",
            message: res.resp_msg,
          });
      }
      return res;
    }
  },
  (error: any) => {
    if (error.response) {
      const res = error.response.data || {};
      if (res.resp_code === 401) {
        toLogin();
        return Promise.resolve(error.response);
      }
      Toast({
        type: "fail",
        message: res.resp_msg || "未知错误",
        duration: 1000,
      });
    }
    return Promise.resolve(error.response);
  }
);
export default http;
