
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from 'vuex';
import flexible from "rem-flexible";

export default defineComponent({
  setup() {
    const store = useStore();
    onMounted(() => {
      flexible(750, 100);
      // url 带参数debug = 1 开启调试
      if (window.location.href.indexOf('debug=1') !== -1) {
        import('vconsole').then(({ default: VConsole }) => {
          (window as any).VConsole = new VConsole();
        });
      }
    });

    // 页面展示（一般用于刷新）
    const pageShow = computed(() => store.state.pageShow);

    return {
      pageShow,
    };
  }
});
