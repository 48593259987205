/*
 * @Author: 郑玉弘
 * @Description: 认证鉴权相关方法
 * @Date: 2022-07-20 15:14:32
 */
import { Toast } from "vant";
import Qs from 'qs';
import store from '@/store';
import router from '@/router';
import { getUrlParams } from "@/utils/tool";
import { getWxOpenId, judgeOpenId, getTokenByUnionId } from "@/api/web";

const query = getUrlParams();

// 判断是否有redirectUrl参数，有的话执行跳转并将授权code传入
// 注意这个参数只用于测试环境转发调试的登录，如果是登录后的跳转用redirect
if (query.redirectUrl) {
  handleRedirect(query, query.redirectUrl);
}

/**
 * @description: 处理测试环境向调试重定向
 * @param {any} query 路由参数（注意这个参数是url的search参数，不是vue路由参数）
 * @param {string} redirectUrl 重定向地址
 */
function handleRedirect(query: any, redirectUrl: string): void {
  const urlObj = new URL(redirectUrl);
  let search = urlObj.search;
  if (query.code) {
    search += search ? '&' : '?';
    search += `code=${query.code}`;
  }
  if (query.state) {
    search += search ? '&' : '?';
    search += `state=${query.state}`;
  }
  window.location.replace(`${urlObj.origin}${urlObj.pathname}${search}${urlObj.hash}`);
}

/**
 * @description: 发起微信登录授权
 */
function wxLogin(): void {
  const isDebug = /^(10\.|172\.16\.|192\.168\.|localhost|127\.0\.0\.1)/.test(location.host);
  const isTest = location.host === 'wp-test.zjfazhi.com' || isDebug;
  const origin = isTest ? 'https://wp-test.zjfazhi.com' : location.origin;
  const redirectUri = isDebug
  ? `${origin}/?redirectUrl=${encodeURIComponent(location.href)}#/login` // 调试用测试环境登录页进行重定向
  : `${origin}${location.pathname}${location.search}${location.hash}`;
  const state = 'AUTH_LOGIN'; // 微信授权state
  const wxAuthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WX_APP_ID}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
  location.replace(wxAuthUrl);
}

/**
 * @description: 跳转登录页
 * @param {any} route vue路由对象
 */
function toLogin(route: any): void {
  const isLogin = route.name === 'Login';
  if (!isLogin) {
    router.push({ name: 'Login' });
  }
}

/**
 * @description: 用微信授权code获取openId
 * @param {any} query 路由参数（注意这个参数是url的search参数，不是vue路由参数）
 * @return {Promise<void>}
 */
async function getOpenId(query: any): Promise<void> {
  try {
    let unionIdRes = await getWxOpenId(query.code);
    if ((unionIdRes as any).resp_code === 0) {
      let data = (unionIdRes as any).datas;
      // 注意返回的微信用户信息是json字符串
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      store.commit('setOpenId', data.openid);
      store.commit('setUnionId', data.unionid);

      // 重新进入，去掉code、state参数
      delete query.code;
      delete query.state;
      const search = Qs.stringify(query, { addQueryPrefix: true });
      window.location.replace(`${location.origin}${location.pathname}${search}${location.hash}`);
      return Promise.reject();
    }
    window.location.replace(`${location.origin}/#/login`);
    return Promise.reject();
  } catch {
    window.location.replace(`${location.origin}/#/login`);
    return Promise.reject();
  }
}

/**
 * @description: 用微信unionId换取token
 * @return {Promise<string>} token
 */
async function getToken(): Promise<string> {
  const tokenRes = await getTokenByUnionId({ unionId: store.state.unionId });
  if ((tokenRes as any).resp_code === 0) {
    // 处理返回结果
    const {
      refresh_token,
      token_type,
      access_token,
      expires_in,
      userType,
      userId,
    } = (tokenRes as any).datas;
    store.commit('setToken', { access_token, token_type, expires_in, refresh_token });
    store.commit("updateUserType", userType);
    store.commit("updateUserId", userId);
    return access_token;
  }
  Toast({
    type: "fail",
    message: (tokenRes as any).resp_msg,
  });
  return '';
}

/**
 * @description: 免登方法（微信code授权）
 * @param {any} toRoute 当前或者将要进入页面路由
 * @return {Promise<boolean | void>}
 */
export const authLogin = async (toRoute?: any): Promise<boolean | void> => {
  const token = store.getters.token;
  if (token) return;

  const route = toRoute || router.currentRoute.value;

  // 判断是否有code，没有openId，执行登录
  if (!query.code && !store.state.openId) {
    // 微信环境的话进行微信登录拿code，否则进登录页
    if (/MicroMessenger/.test(navigator.userAgent)) {
      wxLogin();
      return Promise.reject();
    }
    toLogin(route);
    return Promise.reject(true); // 这里传入true表示需要执行渲染页面
  }

  // 通过code获取微信openId
  if (query.code) {
    // 清理下缓存，避免历史缓存影响
    localStorage.clear();
    sessionStorage.clear();
    store.commit('resetStore');
    await getOpenId(query);
  }

  // 检查openId有没有绑定用户，未绑定用户返回登录
  try {
    const res = await judgeOpenId({ openId: store.state.openId,userType:'WP' });
    // 这个接口比较特殊，未绑定时返回的res没有值
    if (res && (res as any).resp_code === 0) {
      if ((res as any).datas !== -1) {
        toLogin(route);
        return Promise.reject(true); // 这里传入true表示需要执行渲染页面
      }
    } else {
      toLogin(route);
      return Promise.reject(true); // 这里传入true表示需要执行渲染页面
    }
  } catch {
    toLogin(route);
    return Promise.reject(true); // 这里传入true表示需要执行渲染页面
  }

  // 通过unionId获取token
  const accessToken = await getToken();
  if (accessToken) return;
  toLogin(route);
  return Promise.reject(true); // 这里传入true表示需要执行渲染页面
};
