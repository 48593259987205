import Home from "../views/Home.vue";

const routes = [
  {
    path: '/home',
    component: Home,
    children: [
      {
        path: '/task',
        name: 'task',
        meta: {
          title: '任务',
        },
        component: () => import('../views/Task.vue'),
      },
      {
        path: '/resource',
        name: 'resource',
        meta: {
          title: '资讯',
        },
        component: () => import('../views/Resource.vue'),
      },
      {
        path: '/mine',
        name: 'mine',
        meta: {
          title: '我的',
        },
        component: () => import('../views/Mine.vue'),
      },
    ],
  },
  {
    path: '/empty',
    name: 'Empty',
    meta: {
      title: '网络学院',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Empty.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    meta: {
      title: '测试页',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Test.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/retrieve',
    name: 'retrieve',
    meta: {
      title: '找回密码',
    },
    component: () => import('../views/Retrieve.vue'),
  },
  {
    path: '/reset',
    name: 'reset',
    meta: {
      title: '重置密码',
    },
    component: () => import('../views/Reset.vue'),
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: {
      title: '意见反馈',
    },
    component: () => import('../views/Feedback.vue'),
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      title: '账号信息',
    },
    component: () => import('../views/Detail.vue'),
  },
  {
    path: '/modify',
    name: 'modify',
    meta: {
      title: '修改密码',
    },
    component: () => import('../views/Modify.vue'),
  },
  {
    path: '/taskDetail',
    name: 'taskDetail',
    meta: {
      title: '任务详情',
    },
    component: () => import('../views/TaskDetail.vue'),
  },
  {
    path: '/taskView',
    name: 'taskView',
    meta: {
      title: '任务详情',
    },
    component: () => import('../views/TaskView.vue'),
  },
  {
    path: '/taskComplate',
    name: 'taskComplate',
    meta: {
      title: '指令完成依据',
    },
    component: () => import('../views/TaskComplate.vue'),
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      title: '账号绑定',
    },
    component: () => import('../views/Account.vue'),
  },
  {
    path: '/deptList',
    name: 'deptList',
    component: () => import('../views/DeptList.vue'),
  },
  {
    path: '/taskShare/:instructionId/:receiveId',
    name: 'taskShare',
    meta: {
      title: '分享',
    },
    component: () => import('../views/TaskShare.vue'),
  },
  {
    path: '/',
    redirect:'/login'
  },
  {
    // 此为保留路由，用于清理缓存，请勿作为其他页面路由
    path: '/clear',
    name: 'Clear',
    component: () => ({}),
  },
]

export default routes;
