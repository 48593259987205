import http from "@/utils/http";
import Qs from 'qs';
import { IResetParams, PLoginParams, IWebParams } from "@/typings/index";
import { removeEmptyStringVal } from '@/utils/tool'

export const upload = (data: FormData) => {
  return http({
    url: '/api-file/file/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

//找回密码-发送验证码
export const getResetVerificationCode = (params: string) => {
  return http({
    url: '/api-uaa/validata/smsCode/' + params + '/2?uType=WP',
    method: 'get',
    headers: {
      public: true,
    }
  })
}

//找回密码-重置密码
export const resetPassword = (data: IResetParams) => {
  return http({
    url: '/api-user/user/pwdReset',
    method: 'post',
    data
  })
}

//退出登录
export const logout = () => {
  return http({
    url: '/api-uaa/oauth/logout',
    method: 'post',
  })
}

// 密码登录
export const passwordLogin = (data: PLoginParams) => {
  return http({
    url: '/api-uaa/oauth/user/token',
    method: 'post',
    data: removeEmptyStringVal(data),
  });
}

// 新增 、更新宣传员
export const updateWebCommentator = (data: IWebParams) => {
  return http({
    url: '/api-user/webCommentator',
    method: 'post',
    data
  });
}

// 绑定 - 发送验证码
export const sendVcode = (params: string) => {
  return http({
    url: '/api-uaa/validata/smsCode/mobile/type',
    method: 'get',
    params
  });
}

// 绑定 - 校验手机验证码
export const validVcode1 = (params: any) => {
  return http({
    url: '/api-uaa/validata/smsCode/verificationCode?' + Qs.stringify(params),
    method: 'get'
  });
}

// 微信接口获取openId、unionId
export const getWxOpenId = (params: any) => {
  return http({
    url: '/api-editingChannels/wxapp/getOpenIdByCode/' + params,
    method: 'get',
  });
}

interface IParamOpenId {
  openId: string,
  userType: string
}
// 校验openid是否已绑定
export const judgeOpenId = (params: IParamOpenId) => {
  return http({
    url: '/api-user/judgeOpenId?' + Qs.stringify(params),
    method: 'get'
  })
}

// 获取所属单位列表
export const getChiTenant = () => {
  return http({
    url: '/api-user/getChiTenant',
    method: 'get'
  });
}

// 登录 - 发送验证码
export const getVerificationCode = (params: string) => {
  return http({
    url: '/api-uaa/validata/smsCode/' + params + '/1?uType=WP',
    method: 'get',
    headers: {
      public: true,
    }
  })
}
interface ILoginParams {
  deviceId: string,
  validCode: string,
  unionId?: string
  openId?: string
}

// 登录 - 校验手机验证码(成功后登陆获取token)
export const login = (params: ILoginParams) => {
  return http({
    url: '/api-uaa/oauth/mobile/token?' + Qs.stringify(removeEmptyStringVal(params)),
    method: 'post',
    headers: {
      public: true
    }
  });
}

interface ITokenUnionId {
  unionId: string
}
// 通过unionId获取token
export const getTokenByUnionId = (params: ITokenUnionId) => {
  return http({
    url: '/api-uaa/oauth/unionId/token?' + Qs.stringify(params),
    method: 'post'
  });
}

interface IUserUnionId {
  unionId: string
}
// 获取用户信息 - 网络学院跳转用
export const getUserByUnionId = (params: IUserUnionId) => {
  return http({
    url: '/api-uaa/oauth/getUserByOpenId?' + Qs.stringify(params),
    method: 'post'
  });
}

// wx分享错误记录
export const addErrorLog = (data: any) => {
  return http({
    url: '/api-editingChannels/wxmsg/saveWxErrMsg',
    method: 'post',
    data
  })
}
