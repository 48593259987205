import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入iconfont
import './assets/font/iconfont.css';

import {
  Button,
  Form,
  Field,
  Tabbar,
  TabbarItem,
  List,
  PullRefresh,
  DropdownMenu,
  DropdownItem,
  ShareSheet,
  Uploader,
  Image ,
  Cell,
  CellGroup ,
  Tab, 
  Tabs,
  Icon,
  Radio,
  RadioGroup ,
} from 'vant';

import vhCheck from 'vh-check';
vhCheck();

createApp(App)
  .use(store)
  .use(router)
  .use(Button)
  .use(Form)
  .use(Field)
  .use(Tabbar)
  .use(TabbarItem)
  .use(List)
  .use(PullRefresh)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(ShareSheet)
  .use(Uploader)
  .use(Image )
  .use(Cell )
  .use(CellGroup )
  .use(Tab )
  .use(Tabs )
  .use(Icon )
  .use(Radio )
  .use(RadioGroup )
  .mount("#app");
